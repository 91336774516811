(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/detect/assets/javascripts/detect.js') >= 0) return;  svs.modules.push('/components/core/detect/assets/javascripts/detect.js');

svs = svs || {};

(function($, svs, Modernizr) {
  'use strict';

  svs.core = svs.core || {};
  var isServer = typeof exports === 'object';
  svs.core.Detect = function() {
    var device = {};
    var _isPortraitOrientation = true;
    var _previousWidth = 0;
    var _currentBreakPoint = 'xs';
    var _breakpoints = ['xs', 'sm', 'am', 'md', 'lg', 'xl'];
    var _previousDimensions = {
      offsetTop: 0
    };
    var _isPortraitPrevious = true;

    var feature = function(featureName) {
      var features = svs.core.data.features;

      return features && features.indexOf(featureName) > -1;
    };

    var tenant = function(tenant) {
      return svs.core.data.tenant === tenant;
    };

    var formfactor = {
      mobile: function() {
        return (
          svs.core.data.capabilities.formFactor &&
          svs.core.data.capabilities.formFactor === 'mobile'
        );
      },
      desktop: function() {
        return (
          svs.core.data.capabilities.formFactor &&
          svs.core.data.capabilities.formFactor === 'desktop'
        );
      }
    };

    var touch = function() {
      return Boolean('ontouchstart' in window ||
        window.DocumentTouch && document instanceof DocumentTouch);
    };

    var os = {
      iOS: function() {
        return (
          svs.core.data.capabilities.os &&
          (
            svs.core.data.capabilities.os === 'iphone os' ||
            svs.core.data.capabilities.os === 'os x' && touch()
          )
        );
      },
      android: function() {
        return (
          svs.core.data.capabilities.os &&
          svs.core.data.capabilities.os === 'android'
        );
      },
      windows: function() {
        return (
          svs.core.data.capabilities.os &&
          svs.core.data.capabilities.os === 'windows'
        );
      },
      osX: function() {
        return (
          svs.core.data.capabilities.os &&
          svs.core.data.capabilities.os === 'os x' &&
          !touch()
        );
      }
    };

    var browser = {
      chrome: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'chrome'
        );
      },
      android: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'android'
        );
      },
      safari: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'safari'
        );
      },
      firefox: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'firefox'
        );
      },
      msie: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'msie'
        );
      },
      opera: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'opera'
        );
      },
      crios: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'crios'
        );
      },
      ie: function() {
        return (
          svs.core.data.capabilities.browser &&
          svs.core.data.capabilities.browser === 'trident'
        );
      }
    };

    var viewport = {
      width: function() {
        return $(window).width();
      },
      height: function() {
        return $(window).height();
      }
    };

    var screen = {
      retina: function() {
        return Modernizr.mq('only screen and (-moz-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
      },

      small: function() {
        return Modernizr.mq('only screen and (max-width: 320px)');
      },

      medium: function() {
        return Modernizr.mq('only screen and (min-width: 321px) and (max-width: 640px)');
      },

      large: function() {
        return Modernizr.mq('only screen and (min-width: 641px)');
      }
    };

    var isPortraitOrientation = function() {
      return _isPortraitOrientation;
    };

    var isLandscapeOrientation = function() {
      return !_isPortraitOrientation;
    };

    var checkWindowSize = function() {
      var $body = $('body');
      var $window = $(window);
      var height = $window.outerHeight();
      var width = $window.outerWidth();
      var hasOrientationChanged = false;
      var ratio = '14/9'; 

      if (_previousWidth === 0) {
        _isPortraitOrientation = os.iOS() ?
          height > width :
          window.matchMedia('(max-aspect-ratio:14/9)').matches;
        if (_isPortraitOrientation) {
          $body.addClass('orientation-portrait');
        } else {
          $body.addClass('orientation-landscape');
        }
      } else {
        hasOrientationChanged =
          _previousWidth !== width ||
          _isPortraitPrevious !== _isPortraitOrientation;
        if (hasOrientationChanged) {
          if (
            os.iOS() && !_isPortraitOrientation ||
            !os.iOS() &&
              window.matchMedia('(max-aspect-ratio:' + ratio + ')').matches
          ) {
            _isPortraitOrientation = true;
          } else if (
            os.iOS() && _isPortraitOrientation ||
            !os.iOS() &&
              window.matchMedia('(min-aspect-ratio:' + ratio + ')').matches
          ) {
            _isPortraitOrientation = false;
          } else {
            hasOrientationChanged = false;
          }
        }
      }

      _previousWidth = width;
      _isPortraitPrevious = _isPortraitOrientation;

      if (hasOrientationChanged) {
        $body
          .removeClass('orientation-landscape')
          .removeClass('orientation-portrait');

        if (_isPortraitOrientation) {
          $body.addClass('orientation-portrait');
        } else {
          $body.addClass('orientation-landscape');
        }
        if (device.orientation) {
          device.orientation.trigger('change');
        }

        $body
          .removeClass('orientation-landscape')
          .removeClass('orientation-portrait');

        if (_isPortraitOrientation) {
          $body.addClass('orientation-portrait');
        } else {
          $body.addClass('orientation-landscape');
        }
      }
    };

    var checkDimensions = function() {
      if (device.breakpoint.current !== device.breakpoint.was) {
        var $header = document.querySelector('header');
        if ($header && $header.offsetHeight) {
          var offsetTop = $header.offsetHeight + $header.offsetTop;
          var availiableHeight = window.innerHeight - offsetTop;
          var dimensions = {
            offsetTop: offsetTop,
            availiableHeight: availiableHeight
          };

          if (dimensions.offsetTop !== _previousDimensions.offsetTop) {
            $.extend(device.dimensions, dimensions);
            device.dimensions.trigger('change', dimensions);
            _previousDimensions = dimensions;
          }
        }
      }
    };

    var checkCurrentBreakpoint = function() {
      var getCurrentBreakpoint = window
        .getComputedStyle(document.querySelector('body'), ':before')
        .getPropertyValue('content')
        .replace(/"/g, '');
      var tmpBreakpoint = _currentBreakPoint;

      _currentBreakPoint = getCurrentBreakpoint;
      if (tmpBreakpoint !== getCurrentBreakpoint) {
        device.breakpoint.current = getCurrentBreakpoint;
        device.breakpoint.was = tmpBreakpoint;
        checkDimensions();
        device.breakpoint.trigger('change', {
          current: getCurrentBreakpoint,
          was: tmpBreakpoint
        });
      }
    };

    var getCurrentBreapointIndex = function() {
      var index;
      _breakpoints.forEach(function(_bp, i) {
        if (_currentBreakPoint === _bp) {
          index = i;
        }
      });
      return index;
    };

    var checkCurrentBreakpointLessThan = function(bp) {
      var lessThan = false;
      _breakpoints.forEach(function(_bp, i) {
        if (bp === _bp) {
          if (getCurrentBreapointIndex(bp) < i) {
            lessThan = true;
          }
        }
      });
      return lessThan;
    };

    var checkCurrentBreakpointGreaterThan = function(bp) {
      var greaterThan = false;
      _breakpoints.forEach(function(_bp, i) {
        if (bp === _bp) {
          if (getCurrentBreapointIndex(bp) > i) {
            greaterThan = true;
          }
        }
      });
      return greaterThan;
    };

    var checkCurrentBreakpointEquals = function(bp) {
      var equals = false;
      _breakpoints.forEach(function(_bp, i) {
        if (bp === _bp) {
          if (getCurrentBreapointIndex(bp) === i) {
            equals = true;
          }
        }
      });
      return equals;
    };

    device = {
      feature: feature,
      tenant: tenant,
      formfactor: formfactor,
      os: os,
      browser: browser,
      viewport: viewport,
      screen: screen,
      isServer: isServer,
      isBrowser: !isServer
    };

    if (!isServer) {
      device.orientation = new svs.core.Events({
        isPortrait: isPortraitOrientation,
        isLandscape: isLandscapeOrientation
      });
      device.breakpoint = new svs.core.Events({
        current: _currentBreakPoint,
        was: false,
        lessThan: function(size) {
          return checkCurrentBreakpointLessThan(size);
        },
        greaterThan: function(size) {
          return checkCurrentBreakpointGreaterThan(size);
        },
        equals: function(size) {
          return checkCurrentBreakpointEquals(size);
        }
      });
      device.dimensions = new svs.core.Events(_previousDimensions);

      if (os.android() || os.iOS()) {
        window.addEventListener(
          'resize',
          function(e) {
            checkWindowSize(e);
          },
          false
        );
        checkWindowSize();
      }
      var resizeTimer;
      window.addEventListener(
        'resize',
        function() {
          resizeTimer = setTimeout(function() {
            checkCurrentBreakpoint();
            clearTimeout(resizeTimer);
          }, 10);
        },
        false
      );
      checkCurrentBreakpoint();
      checkDimensions();
    }

    if (typeof window !== 'undefined') {
      window.svs = window.svs || {};
      window.svs.isServer = device.isServer;
      window.svs.isBrowser = device.isBrowser;
    }
    if (typeof global !== 'undefined') {
      global.svs = global.svs || {};
      global.svs.isServer = device.isServer;
      global.svs.isBrowser = device.isBrowser;
    }

    return device;
  };

  svs.core.detect = new svs.core.Detect();

  if (isServer) {
    module.exports = {
      feature: svs.core.detect.feature,
      tenant: svs.core.detect.tenant
    };
  }
})(
  typeof exports === 'object' ? null : jQuery,
  svs,
  typeof exports === 'object' ? null : Modernizr
);


 })(window);