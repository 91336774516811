(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/detect/assets/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/core/detect/assets/helpers/handlebars-helpers.js');
var isServerSide = typeof exports === 'object';
var trinidad;

function registerHelpers(hbs, config) {
  hbs.registerHelper('isMobile', function(options) {
    var isMobile = false;

    if (isServerSide) {
      if (options.data.root.capabilities) {
        isMobile = options.data.root.capabilities.formFactor === 'mobile';
      }
    } else {
      isMobile = svs.core.detect.formfactor.mobile();
    }
    if (options.fn) {
      if (isMobile) {
        return options.fn(this);
      }
      return options.inverse(this);
    }
    return isMobile;
  });

  hbs.registerHelper('isDesktop', function(options) {
    var isDesktop = false;

    if (isServerSide) {
      isDesktop = options.data.root.capabilities.formFactor === 'desktop';
    } else {
      isDesktop = svs.core.detect.formfactor.desktop();
    }
    if (options.fn) {
      if (isDesktop) {
        return options.fn(this);
      }
      return options.inverse(this);
    }
    return isDesktop;
  });

  hbs.registerHelper('tenant', function(value, options) {
    var tenant = false;

    if (isServerSide) {
      tenant = config.options.tenant === value;
    } else {
      tenant = svs.core.data.tenant === value;
    }
    if (options.fn) {
      if (tenant) {
        return options.fn(this);
      }
      return options.inverse(this);
    }
    return tenant;
  });

  hbs.registerHelper('feature', function(value, options) {
    var featureExists = false;

    if (isServerSide) {
      featureExists =
        options.data.root.features &&
        options.data.root.features.indexOf(value) > -1;
    } else {
      featureExists = svs.core.detect.feature(value);
    }

    if (featureExists) {
      return options.fn(this);
    }
    return options.inverse(this);
  });
}

if (isServerSide) {
  var path = require('path');

  trinidad = require(path.join(__dirname, '..', '..', '..', '..', '..', 'core')).core;

  registerHelpers(require('hbs'), trinidad.config);
} else {
  registerHelpers(Handlebars, { options: svs.core.config.data.svsconfig });
}


 })(window);